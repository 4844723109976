<template>
  <section class="account container">
    <h1 class="text__center padding__md">{{ getUserDetails.name.first }}'s Account</h1>
    <div class="account__grid">
      <UserDetails :user="getUserDetails" />
      <UserDelegates :user="getUserDetails" />
    </div>
    <section class="grid__two margin__md">
      <section class="tile">
        <h2 class="p-tb-sm">Past Orders</h2>
        <order-grid :orders="getHomeOrders" />
        <p class="text__right">
          <router-link to="/my-account/past-orders" class="breadcrumb">
            See All Orders
            <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
          </router-link>
        </p>
      </section>
      <section class="tile">
        <h2 class="p-tb-sm">Favorite Orders</h2>
        <favorite-grid :favorites="getHomeFavorites" />
        <p class="text__right">
          <router-link to="/my-account/past-orders?favorite=true" class="breadcrumb">
            See All Favorites
            <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
          </router-link>
        </p>
      </section>
    </section>
    <section class="margin__md">
      <BatchOrders />
    </section>
    <messages :user="getUserDetails" />
    <Help />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import UserDetails from "./UserDetails";
import UserDelegates from "./UserDelegates";
import BatchOrders from "./BatchOrders";
import Help from "./Help";
export default {
  components: {
    UserDetails,
    UserDelegates,
    BatchOrders,
    Help
  },
  metaInfo: {
    title: "My Account | WOW"
  },
  computed: {
    ...mapGetters(["getUserDetails", "getHomeOrders", "getHomeFavorites"])
  }
};
</script>

<style scoped lang="scss">
.account__grid {
  @include grid($cols: 1fr, $col-gap: 25px, $row-gap: 25px);
}
@media (min-width: $md) {
  .account__grid {
    grid-template-columns: 1fr 1fr;
  }
}
</style>