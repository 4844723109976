<template>
  <section class="account-delegates tile">
    <div class="flex__between p-tb-sm">
      <h2 class="text__center">Delegated Users</h2>
      <button class="btn btn__sm btn__red" @click="toggleEditing">{{ !editing ? "Edit" : "Cancel" }}</button>
    </div>
    <p
      class="text__center"
    >These are users with access to viewing your orders, ability to submit orders on your behalf, and use your award balance</p>
    <simplebar v-if="!editing" class="minor--overflow" data-simplebar-auto-hide="false">
      <div
        v-if="user.delegatedUsers.length <= 0"
        class="flex__center account-delegates--holder"
        @click="toggleEditing"
      >
        <p class="text__center text__bold">No delegates selected</p>
      </div>
      <div v-else class="account-delegates--view">
        <ul class="account-delegates__grid">
          <li v-for="person in user.delegatedUsers" :key="person._id" class="text__center">
            <a :href="`mailto: ${person.email}`">{{ person.displayName }}</a>
          </li>
        </ul>
      </div>
    </simplebar>
    <div v-else class="account-delegates--edit">
      <div class="search--bar mb-15">
        <input type="text" v-model="search" />
        <div class="btn__search">
          <font-awesome-icon icon="search" size="lg" />
        </div>
      </div>
      <simplebar class="minor--overflow" data-simplebar-auto-hide="false">
        <p v-if="filteredUsers.length <= 0" class="text__bold text__center">No results for search</p>
        <ul v-else class="delegates-list">
          <li class="delegates-list--item" v-for="person in filteredUsers" :key="person._id">
            <label>
              <input type="checkbox" v-model="form.delegatedUsers" :value="person._id" />
              {{ person.displayName }}
            </label>
          </li>
        </ul>
      </simplebar>
      <div class="flex__between p-tb-sm">
        <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
        <button class="btn btn__sm btn__green" @click="submit">Done</button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["user"],
  data() {
    return {
      editing: false,
      search: "",
      form: {
        _id: this.user ? this.user._id : null,
        delegatedUsers: this.user
          ? this.user.delegatedUsers.map(item => item._id)
          : []
      },
      users: null
    };
  },
  computed: {
    filteredUsers() {
      if (!this.users) return [];
      if (!this.search)
        return this.users.filter(user => user._id !== this.user._id);
      return this.users.filter(item => {
        return (
          item.displayName.toLowerCase().includes(this.search.toLowerCase()) ||
          item.email.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateSelf", "loadDelegates"]),
    toggleEditing() {
      if (!this.users && !this.editing) this.loadUsers();
      this.editing = !this.editing;
    },
    async submit() {
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        await this.startUpdateSelf(this.form);
        this.startSetIsSubmitting({ bool: false });
        this.toggleEditing();
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    async loadUsers() {
      this.startSetIsSubmitting({ bool: true, heading: "Loading Users" });
      try {
        this.users = await this.loadDelegates();
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    }
  }
};
</script>

<style scoped lang="scss">
</style>