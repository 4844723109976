<template>
  <section class="account-details tile">
    <div class="flex__between p-tb-sm">
      <h2>User Details</h2>
      <button v-if="!editing" class="btn btn__sm btn__red" @click="toggleEditing">Edit</button>
      <button v-else class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
    </div>
    <div class="account-details__grid p-tb-sm">
      <label class="flex__between" :class="[errors.first ? 'form--error' : null]">
        <span class="text__bold mr-10">First Name:</span>
        <span class="text__right text__overflow">{{ user.name.first }}</span>
        <!-- <input v-else type="text" v-model="form.name.first" placeholder="first" /> -->
      </label>
      <form-error v-if="editing" :message="errors.first" />
      <label class="flex__between" :class="[errors.last ? 'form--error' : null]">
        <span class="text__bold mr-10">Last Name:</span>
        <span class="text__right text__overflow">{{ user.name.last }}</span>
        <!-- <input v-else type="text" v-model="form.name.last" placeholder="last" /> -->
      </label>
      <form-error v-if="editing" :message="errors.last" />
      <label class="flex__between" :class="[errors.email ? 'form--error' : null]">
        <span class="text__bold mr-10">Email:</span>
        <span class="text__right text__overflow">
          <a :href="`mailto: ${form.email}`" class="link__none">{{ user.email }}</a>
        </span>
        <!-- <input v-else type="email" v-model="form.email" placeholder="fb email address" /> -->
      </label>
      <form-error v-if="editing" :message="errors.email" />
      <label class="flex__between" :class="[errors.phone ? 'form--error' : null]">
        <span class="text__bold mr-10">Phone:</span>
        <span v-if="!editing" class="text__right text__overflow">
          <a :href="`tel: ${user.phone}`" class="link__none">{{ user.phone }}</a>
        </span>
        <input
          v-else
          type="tel"
          v-model="form.phone"
          placeholder="(111) 111-1111"
          v-mask="'(###) ###-####'"
        />
      </label>
      <form-error v-if="editing" :message="errors.phone" />
      <label class="flex__between">
        <span class="text__bold mr-10">Batch Access:</span>
        <span v-if="user.bulkEnabled" class="text__right">Yes</span>
        <router-link v-else to="/contact-us?topic=bulk" class="link__none">Request Access</router-link>
      </label>
      <label class="flex__between">
        <span class="text__bold mr-10">Award Balance:</span>
        <span class="text__right">${{ form.awardBalance.toFixed(2) }}</span>
      </label>
      <div v-if="editing" class="flex__between p-tb-sm">
        <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
        <button class="btn btn__sm btn__green" @click="submit">Done</button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["user"],
  data() {
    return {
      editing: false,
      isValidated: true,
      form: {
        _id: this.user._id,
        permission: this.user.permission,
        name: {
          first: this.user.name.first ? this.user.name.first : "",
          last: this.user.name.last ? this.user.name.last : ""
        },
        // email: this.user.email ? this.user.email : "",
        phone: this.user.phone ? this.user.phone : "",
        awardBalance: this.user.awardBalance
      },
      errors: {
        first: null,
        last: null,
        email: null,
        phone: null
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateSelf"]),
    toggleEditing() {
      this.editing = !this.editing;
    },
    emailTest(email) {
      const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      const reFBHL = /@fbhl.com/ || /@mollydeanteam.com/;
      return re.test(email) && reFBHL.test(email);
    },
    async submit() {
      this.validate();
      if (this.isValidated) {
        this.startSetIsSubmitting({ bool: true, heading: "Updating" });
        try {
          await this.startUpdateSelf(this.form);
        } catch (err) {
          this.startSetIsSubmitting({ bool: false });
          return;
        }
        this.toggleEditing();
        this.startSetIsSubmitting({ bool: false });
      }
    },
    validate() {
      this.isValidated = true;
      this.errors.first =
        this.form.name.first === "" ? "Please enter a first name" : null;
      this.errors.last =
        this.form.name.last === "" ? "Please enter a last name" : null;
      // this.errors.email = !this.emailTest(this.form.email)
      //   ? "Please enter a valid fbhl address"
      //   : null;
      if (this.form.phone.length > 0) {
        this.errors.phone =
          this.form.phone.length < 14
            ? "Please enter a valid phone number"
            : null;
      } else {
        this.errors.phone = null;
      }
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.account-details__grid {
  @include grid($cols: 1fr, $row-gap: 15px, $col-gap: 10px, $align: center);
  label {
    @include grid($cols: 1fr 2fr, $col-gap: 10px);
  }
}
</style>